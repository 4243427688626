import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03a54b1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search_bar" }
const _hoisted_2 = { class: "wrapper_input" }
const _hoisted_3 = { class: "itemRouteWrapper" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "actions_bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_global_filters = _resolveComponent("global-filters")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.buscaRota) = $event)),
        class: "custom_input",
        placeholder: "Buscar",
        id: "inputSearchRoute"
      }, null, 512), [
        [_vModelText, _ctx.buscaRota]
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRoutes, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "item_route",
            key: index,
            onClick: ($event: any) => (_ctx.redirectToRoute(item))
          }, [
            _createElementVNode("span", null, _toDisplayString(item.name), 1),
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-menu-right ")
              ]),
              _: 1
            }),
            _createElementVNode("span", null, _toDisplayString(item.child), 1)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_btn, {
        icon: "mdi-magnify",
        class: "search_icon_actions"
      }),
      _createVNode(_component_global_filters)
    ])
  ]))
}