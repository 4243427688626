
import { defineComponent } from "vue";
import ListChild from "./ListChild.vue";

import * as RT from "../../interfaces/rotas";

export default defineComponent({
  components: { ListChild },
  props: {
    navDesktopOpened: Boolean,
  },
  data() {
    return {
      menus: [] as RT.rotas[],
    };
  },
  computed: {
    filteredMenus() {
      return this.menus
        .filter(
          (e) => !e.meta?.adminOnly || this.$store.state.userInfo.tipo == 1,
        )
        .filter(
          (e) =>
            e.name !== "agregaParticipantes" ||
            this.$store.state.userInfo.redistribuicao == true,
        )
        .filter((e) => e.meta?.visible != false);
    },
  },

  mounted() {
    let items = this.$router.options.routes.filter((e) => {
      return !!e.meta?.vicon && e.path;
    });
    this.menus = [];

    items.forEach((e) => {
      this.menus.push({
        meta: e.meta as any,
        level: 1,
        children: e.children as any,
        path: e.path.toString(),
        expanded: false,
      });
    });
  },
});
