import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "@/axios-config";

import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import mapData from "@highcharts/map-collection/custom/world.geo.json";
import patternFill from "highcharts/modules/pattern-fill";
import HighchartsBoost from "highcharts/modules/boost";

// import RollbarPlugin from "./rollbar";

HighchartsBoost(Highcharts);
mapInit(Highcharts);
patternFill(Highcharts);
Highcharts.maps["myMapName"] = mapData;

Highcharts.setOptions({
  chart: {
    backgroundColor: "var(--app-background)",
  },
  accessibility: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  legend: {
    itemStyle: {
      color: "var(--text-default)",
    },
  },

  yAxis: {
    title: {
      style: {
        color: "var(--text-default)",
      },
    },
  },
  tooltip: {
    hideDelay: 0,
  },

  colors: [
    "var(--chart-primary)",
    "var(--chart-secondary)",
    "var(--chart-tertiary)",
    "var(--chart-4)",
    "var(--chart-5)",
  ],

  plotOptions: {
    series: {
      dataLabels: {
        style: {
          color: "var(--text-default)",
        },
        useHTML: true,
      },
    },
  },

  lang: {
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    shortMonths: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    weekdays: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ],
    loading: "Atualizando o gráfico...aguarde",
    contextButtonTitle: "Exportar gráfico",
    decimalPoint: ",",
    thousandsSep: ".",
    downloadJPEG: "Baixar imagem JPEG",
    downloadPDF: "Baixar arquivo PDF",
    downloadPNG: "Baixar imagem PNG",
    downloadSVG: "Baixar vetor SVG",
    printChart: "Imprimir gráfico",
    rangeSelectorFrom: "De",
    rangeSelectorTo: "Para",
    rangeSelectorZoom: "Zoom",
    resetZoom: "Limpar Zoom",
    resetZoomTitle: "Voltar Zoom para nível 1:1",
  },
});

import "./css/reset.css";
import "./css/variables.css";
import "./css/global.css";

loadFonts();

const app = createApp(App).use(router).use(store).use(vuetify);

app.config.globalProperties.$axios = axios;

app.config.globalProperties.$formatNumber = function (amount, decimals = 2) {
  if (typeof amount !== "number") {
    return "";
  }
  return amount.toLocaleString("pt-br", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

app.mount("#app");
