
import { defineComponent } from "vue";

import GlobalFilters from "./GlobalFilter/GlobalFilters.vue";

export default defineComponent({
  components: {
    GlobalFilters,
  },

  created() {
    document.addEventListener("click", this.limparBuscador);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.limparBuscador);
  },

  data() {
    return {
      buscaRota: null as any,
      menus: [] as any[],
      activeElem: document.activeElement,
    };
  },

  mounted() {
    this.menus = [];

    let items: any = this.$router.options.routes.filter((e) => {
      return !!e.meta?.vicon && e.path && e.children;
    });

    this.menus = items.reduce((acc: any, curr: any) => {
      if (curr.children) {
        curr.children.forEach((element: any) => {
          const requiresAdmin = element.meta.adminOnly;

          const userData = this.$store.state.userInfo;

          if (userData) {
            const user = userData;
            const userType = user.tipo;
            const redistribuicao = user.redistribuicao;

            // Tipo 0: Normal
            // Tipo 1: Admin

            if (requiresAdmin && userType != 1) {
              return;
            }
            if (element.meta?.title == "Agrega" && redistribuicao) {
              return;
            }
          }

          acc.push({
            name: curr.meta?.title,
            child: element.meta?.title,
            path: element.path,
          });
        });
      }

      return acc;
    }, []);
  },

  computed: {
    filteredRoutes() {
      let termo = this.buscaRota?.toLowerCase();

      if (!termo || termo === " ") {
        return [];
      }

      let result = this.menus.filter((e) => {
        return (
          e.name.toLowerCase().includes(termo) ||
          e.child.toLowerCase().includes(termo)
        );
      });

      if (result.length === 0) {
        return [];
      }
      return result;
    },
  },

  methods: {
    limparBuscador(event: any) {
      var input = document.getElementById("inputSearchRoute");

      if (event.target !== input && !input?.contains(event.target)) {
        this.buscaRota = null;
      }
    },

    redirectToRoute(item: any) {
      this.buscaRota = "";
      this.$store.commit("routeRedirected", 1);
      this.$router.push({ path: item.path });
    },
  },
});
