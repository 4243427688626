
import router from "@/router";
import { defineComponent } from "vue";
import { loginResponse } from "../../interfaces/login";
import axios from "@/axios-config";

import store from "@/store";
import MockAdapter from "axios-mock-adapter";

if (store.state.MockLogin) {
  const mock = new MockAdapter(axios);
  mock.onGet("/Usuarios/isAuthenticated").reply(200, {});
}

if (store.state.MockLogin) {
  const mock = new MockAdapter(axios);
  mock.onPost("/Usuarios/login").reply(200, {
    usuario: {
      id: 2,
      nome: "Bruno Jaime",
      email: "bruno.jaime@implantait.com.br",
      matricula: "888888",
      cpf: "99999999999",
      status: true,
      tipo: 0,
    },
    token: "teste",
  });
}

export default defineComponent({
  data() {
    return {
      email: "",
      senha: "",
      hidePassword: true,

      buttonLoading: false,

      ErrorLogin: false,

      buttonLabel: "Entrar",
    };
  },
  methods: {
    login() {
      this.buttonLoading = true;

      let params = {
        email: this.email,
        senha: this.senha,
      };

      axios
        .post("/Usuarios/login", params)
        .then((res: loginResponse) => {
          this.setToken(res.data.token);

          this.setUser(res.data.usuario);

          this.buttonLoading = false;

          router.push({ name: "home" });
        })
        .catch(() => {
          this.loginError();
        });
    },

    loginError() {
      this.buttonLoading = false;
      this.ErrorLogin = true;

      this.buttonLabel = "E-mail e/ou senha incorretos";
      window.addEventListener("click", this.resetLabel);
    },

    resetLabel() {
      this.buttonLabel = "Entrar";
      this.ErrorLogin = false;
      window.removeEventListener("click", this.resetLabel);
    },
    setUser(user: object) {
      this.$store.commit("defineUserInfo", user);
    },

    setToken(token: string) {
      const expirationTime = 24 * 60 * 60 * 1000;

      const expirationDate = new Date(new Date().getTime() + expirationTime);

      const cookieString = `session=${token}; expires=${expirationDate.toUTCString()}; path=/; SameSite=Strict`;

      document.cookie = cookieString;
      this.$axios.defaults.headers.Authorization = `Bearer ${token}`;
    },
  },
});
