import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-350b6b04"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMouseenter", "onMouseleave", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_list_child = _resolveComponent("list-child", true)!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (child, index) => {
    return (_openBlock(), _createElementBlock("li", {
      key: child.path,
      class: "fade_in",
      style: _normalizeStyle([index !== 0 ? { 'margin-top': '52px' } : { 'margin-top': 0 }]),
      onMouseenter: ($event: any) => (
      !_ctx.$store.state.isMobile
        ? (child.expanded = true)
        : (child.expanded = child.expanded)
    ),
      onMouseleave: ($event: any) => (
      !_ctx.$store.state.isMobile
        ? (child.expanded = false)
        : (child.expanded = child.expanded)
    ),
      onClick: _withModifiers(($event: any) => (_ctx.redirectRoute(child)), ["stop"])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flexChild", { flexColumn: _ctx.$store.state.isMobile }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({
          'first-item':
            index === 0 && _ctx.navDesktopOpened && !_ctx.$store.state.isMobile,
          'mobile-menu': _ctx.$store.state.isMobile,
          contentMenu: true,
        }),
          style: _normalizeStyle({
          width: _ctx.$store.state.isMobile ? '100%' : '300px',
        })
        }, [
          _createElementVNode("span", {
            style: _normalizeStyle([{"position":"absolute"}, {
            marginLeft: _ctx.$store.state.isMobile ? _ctx.indent + 'px' : '10px',
          }])
          }, [
            _createTextVNode(_toDisplayString(child.meta?.title) + " ", 1),
            _withDirectives(_createVNode(_component_v_icon, { class: "fade_in" }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-chevron-right ")
              ]),
              _: 2
            }, 1536), [
              [_vShow, _ctx.$store.state.isMobile && !child.expanded && child.children]
            ]),
            _withDirectives(_createVNode(_component_v_icon, { class: "fade_in" }, {
              default: _withCtx(() => [
                _createTextVNode(" mdi-chevron-down ")
              ]),
              _: 2
            }, 1536), [
              [_vShow, _ctx.$store.state.isMobile && child.expanded && child.children]
            ])
          ], 4)
        ], 6),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_list_child, {
            items: child.children,
            navDesktopOpened: _ctx.navDesktopOpened,
            indent: _ctx.indent + 30
          }, null, 8, ["items", "navDesktopOpened", "indent"])
        ], 512), [
          [_vShow, child.expanded]
        ])
      ], 2)
    ], 44, _hoisted_1))
  }), 128))
}