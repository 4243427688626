
import { defineComponent } from "vue";
import LoadingBoxes from "./components/DefaultComponents/LoadingBoxes.vue";
import InfoPopup from "./components/DefaultComponents/InfoPopup.vue";
import MainView from "./views/MainView.vue";
import LoginView from "./views/LoginView.vue";
import MenuUserDesktop from "@/components/MainTemplate/MenuUserDesktop.vue";
import MenuUserMobile from "@/components/MainTemplate/MenuUserMobile.vue";
// import CardManutencao from "./components/DefaultComponents/CardManutencao.vue";

import axios from "@/axios-config";

export default defineComponent({
  name: "App",
  components: {
    LoadingBoxes,
    MainView,
    LoginView,
    MenuUserDesktop,
    MenuUserMobile,
    InfoPopup,
    // CardManutencao,
  },

  data() {
    return {
      loadingSession: true,
      // dadosSobSuspeita: false,
    };
  },

  mounted() {
    document.title = "Painel Sincronia";
    document.documentElement.style.setProperty("--nav-width", "300px");
    document.documentElement.style.setProperty("--app-background", "#fff");

    axios
      .get("/Usuarios/isAuthenticated")
      .then((res) => {
        this.loadingSession = false;
        this.$store.commit("defineUserInfo", res.data);
      })
      .catch(() => {
        this.loadingSession = false;
      });

    // axios
    //   .get("/Usuarios/getDadosSobSuspeita")
    //   .then((res) => {
    //     this.dadosSobSuspeita = res.data;
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  },

  methods: {
    logoff() {
      this.$store.commit("defineSessaoExpirada", false);
      document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$router.push("/login");
    },
  },
});
