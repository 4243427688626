
import { defineComponent } from "vue";

import * as RT from "../../interfaces/rotas";

export default defineComponent({
  props: {
    navDesktopOpened: Boolean,
    items: Array as () => RT.rotas[] | null,
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visibleItems: [] as RT.rotas[] | null,
    };
  },

  mounted() {
    this.filterItemsByUserType();
  },
  methods: {
    filterItemsByUserType() {
      let tipoUsuario = this.$store.state.userInfo.id;

      this.visibleItems =
        this.items
          ?.filter((e) => !e.meta?.adminOnly || tipoUsuario)
          .filter(
            (e) =>
              e.name !== "agregaParticipantes" ||
              !this.$store.state.userInfo.redistribuicao,
          )
          .filter((e) => e.meta?.visible != false) || [];
    },
    redirectRoute(item: RT.rotas) {
      if (this.$store.state.isMobile) {
        item.expanded = !item.expanded;
      }

      if (!item.children) {
        this.$store.commit("routeRedirected", 1);
        this.$router.push({ path: item.path });
      }
    },
  },
});
