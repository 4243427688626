import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-150151d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMouseenter", "onMouseleave", "onClick"]
const _hoisted_2 = { class: "contentMenu" }
const _hoisted_3 = {
  key: 0,
  class: "menu_text fade_in"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_list_child = _resolveComponent("list-child")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredMenus, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        key: item.path,
        onMouseenter: ($event: any) => (
        !_ctx.$store.state.isMobile
          ? (item.expanded = true)
          : (item.expanded = item.expanded)
      ),
        onMouseleave: ($event: any) => (
        !_ctx.$store.state.isMobile
          ? (item.expanded = false)
          : (item.expanded = item.expanded)
      ),
        onClick: _withModifiers(($event: any) => (
        _ctx.$store.state.isMobile
          ? (item.expanded = !item.expanded)
          : (item.expanded = item.expanded)
      ), ["stop"]),
        class: _normalizeClass(["menu", {
        flexColumn: _ctx.$store.state.isMobile,
        activeMenu: item.path === _ctx.$route?.matched[0]?.path,
      }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.meta?.vicon), 1)
            ]),
            _: 2
          }, 1536), [
            [_vShow, item.meta?.vicon]
          ]),
          (_ctx.$store.state.isMobile || _ctx.navDesktopOpened)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.meta?.title), 1))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_v_icon, { class: "fade_in" }, {
            default: _withCtx(() => [
              _createTextVNode(" mdi-chevron-right ")
            ]),
            _: 2
          }, 1536), [
            [_vShow, 
            (!_ctx.$store.state.isMobile && _ctx.navDesktopOpened) ||
            (_ctx.$store.state.isMobile && !item.expanded)
          ]
          ]),
          _withDirectives(_createVNode(_component_v_icon, { class: "fade_in" }, {
            default: _withCtx(() => [
              _createTextVNode(" mdi-chevron-down ")
            ]),
            _: 2
          }, 1536), [
            [_vShow, _ctx.$store.state.isMobile && item.expanded]
          ])
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_list_child, {
            items: item.children,
            navDesktopOpened: _ctx.navDesktopOpened,
            indent: 40
          }, null, 8, ["items", "navDesktopOpened"])
        ], 512), [
          [_vShow, item.expanded]
        ])
      ], 42, _hoisted_1))
    }), 128))
  ]))
}