
import { defineComponent } from "vue";
import MenuIcon from "./MenuIcon.vue";
import UserMenu from "./UserMenu.vue";
import NavMenus from "./NavMenus.vue";
import SearchBar from "./SearchBar.vue";
// import Notificacoes from "./Notificacoes.vue";
// import Acessibilidade from "./Acessibilidade.vue";

import store from "@/store";

export default defineComponent({
  name: "MainNav",

  components: {
    MenuIcon,
    UserMenu,
    NavMenus,
    SearchBar,
    // Notificacoes,
    // Acessibilidade,
  },

  data() {
    return {
      imgCliente: require("../../assets/logo_cliente.png"),
      imgImplanta: require("../../assets/logo_implanta.png"),

      loginOut: false,

      navMobileOpened: false,

      navDesktopOpened: true,

      screenWidth: window.innerWidth,

      changeTheme: false,

      callUpdateScreenTimeout: null as any,

      varThemeWhite: {
        "--app-primary": "#004a96",
        "--background-login": "#fff",
        "--app-background": "#f3f3f3",

        "--custom-access-bg": "#004a96",
        "--custom-access-bg-estoque": "#f39500",

        "--text-default": "#000000",
        "--cards-bg": "#FFFFFF",

        "--app-aviso": "#f39500",
        "--card-value-color": "var(--app-aviso)",

        "--chart-primary": "#21A0D2",
        "--chart-secondary": "#F39500",

        "--bg-shimmer1": "#ffffff00",
        "--bg-shimmer2": "#eeeeee",
        "--bg-shimmer3": "#bbbbbb",

        "--title-chart-default": "#9b9b9b",

        "--row-table-bg": "#dedede71",

        "--bg-card-vendas": "#fff",
      } as any,

      varThemeDark: {
        "--background-login": "#BFC5CF",
        "--app-primary": "#1C8F9C",
        "--app-background": "#161F2D",

        "--custom-access-bg": "#FFFFFF",
        "--custom-access-bg-estoque": "#FFFFFF",

        "--text-default": "#FFFFFF",
        "--cards-bg": "#4C4C4C",

        "--app-aviso": "#1C8F9C",
        "--card-value-color": "#D5560F",

        "--chart-primary": "#23A5BF",
        "--chart-secondary": "#D5560F",

        "--bg-shimmer1": "#fe000000",
        "--bg-shimmer2": "#7f7e7e",
        "--bg-shimmer3": "#959595",

        "--title-chart-default": "#fff",

        "--row-table-bg": "#313946",

        "--bg-card-vendas": "#000",
      } as any,
    };
  },
  watch: {
    "$store.state.redirectRoute": function () {
      this.navMobileOpened = false;
    },

    changeTheme() {
      store.commit("defineTheme", this.changeTheme);

      if (this.changeTheme === true) {
        this.turnThemeBlack();

        return;
      }

      this.turnThemeWhite();
    },
  },
  mounted() {
    if (this.screenWidth >= 960) {
      this.$store.commit("defineMobile", false);
    }

    window.addEventListener("resize", this.callUpdateScreen);

    let nw = document.documentElement.style.getPropertyValue("--nav-width");

    if (nw === "300px") {
      this.navDesktopOpened = true;
      return;
    }
    this.navDesktopOpened = false;

    this.turnThemeWhite();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.callUpdateScreen);
  },

  methods: {
    openVisaoGeral() {
      this.$router.push("sellout-agricultores");
    },
    turnThemeBlack() {
      for (const property in this.varThemeDark) {
        document.documentElement.style.setProperty(
          property,
          this.varThemeDark[property],
        );
      }
    },

    turnThemeWhite() {
      for (const property in this.varThemeWhite) {
        document.documentElement.style.setProperty(
          property,
          this.varThemeWhite[property],
        );
      }
    },
    toggleNavBar() {
      if (this.navMobileOpened) {
        this.navMobileOpened = false;
        return;
      }
      this.navMobileOpened = true;
    },

    openUserMenu(tipo: string) {
      if (tipo === "mobile") {
        this.$store.commit("defineMobileMenu", !this.$store.state.menuMobile);
      }
      if (tipo === "desk") {
        this.$store.commit("defineDesktopMenu", !this.$store.state.menuDesktop);
      }
    },

    toggleNavDesktop() {
      let nw = document.documentElement.style.getPropertyValue("--nav-width");

      if (nw === "300px") {
        document.documentElement.style.setProperty("--nav-width", "70px");

        this.$store.commit("navMini", true);

        this.navDesktopOpened = false;

        return;
      }

      document.documentElement.style.setProperty("--nav-width", "300px");
      this.$store.commit("navMini", false);
      this.navDesktopOpened = true;
    },

    callUpdateScreen() {
      clearTimeout(this.callUpdateScreenTimeout);

      this.callUpdateScreenTimeout = setTimeout(() => {
        this.updateScreenWidth();
      }, 250);
    },

    updateScreenWidth() {
      this.$store.commit("defineResizeScreen");

      this.screenWidth = window.innerWidth;

      if (this.screenWidth >= 960) {
        this.$store.commit("defineMobile", false);
        return;
      }

      this.$store.commit("defineMobile", true);
    },
    logout() {
      this.$router.push("/login");

      document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
  },
});
