import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_boxes = _resolveComponent("loading-boxes")!
  const _component_main_view = _resolveComponent("main-view")!
  const _component_login_view = _resolveComponent("login-view")!
  const _component_menu_user_desktop = _resolveComponent("menu-user-desktop")!
  const _component_menu_user_mobile = _resolveComponent("menu-user-mobile")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_info_popup = _resolveComponent("info-popup")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, {
    theme: _ctx.$store.state.darkTheme ? 'dark' : 'light',
    class: "application_container"
  }, {
    default: _withCtx(() => [
      (_ctx.loadingSession)
        ? (_openBlock(), _createBlock(_component_loading_boxes, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.loadingSession && _ctx.$route.path !== '/login')
        ? (_openBlock(), _createBlock(_component_main_view, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "showapp" }, {
        default: _withCtx(() => [
          (!_ctx.loadingSession && _ctx.$route.path === '/login')
            ? (_openBlock(), _createBlock(_component_login_view, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.$store.state.menuDesktop)
        ? (_openBlock(), _createBlock(_component_menu_user_desktop, { key: 2 }))
        : _createCommentVNode("", true),
      (_ctx.$store.state.menuMobile)
        ? (_openBlock(), _createBlock(_component_menu_user_mobile, { key: 3 }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.$store.state.snack.active,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$store.state.snack.active) = $event)),
        bottom: true,
        timeout: 7000,
        class: _normalizeClass([{
        Snackbar_Error: _ctx.$store.state.snack.type === 'error',
        Snackbar_Warning: _ctx.$store.state.snack.type === 'warning',
        Snackbar_Success: _ctx.$store.state.snack.type === 'success',
      }, "Snackbar_Neutral"]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.commit('defineSnack', { active: false })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$store.state.snack.text), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "class"]),
      _createVNode(_component_v_dialog, {
        modelValue: _ctx.$store.state.sessaoExpirada,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$store.state.sessaoExpirada) = $event)),
        width: "auto",
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(" Sua sessão expirou. Faça Login novamente. ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    style: {"color":"var(--app-primary)"},
                    block: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.logoff()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Fazer Login ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_info_popup)
    ]),
    _: 1
  }, 8, ["theme"]))
}