<template>
  <transition name="fade">
    <div
      v-if="expanded"
      class="popup card_wrapper"
      @click="expanded = !expanded"
    >
      <div class="header">
        <object
          type="image/png"
          :data="svgWarningYellow"
          width="48"
          height="48"
        >
          Seu navegador não suporta SVG.
        </object>
        <h2 class="title">IMPORTANTE</h2>
      </div>
      <!-- Conteúdo do pop-up -->
      <div class="content">
        <p>
          Tipos de operações consideradas no painel: Vendas, venda de origem
          entrega futura e devolução de venda.
        </p>
        <br />
        <p>
          No painel está sendo considerado somente os dados de crop e seeds.
          Dica: utilize os filtros de categoria para filtrar somente o seu
          interesse.
        </p>
      </div>
    </div>
    <div v-else>
      <div class="popup_icon">
        <object
          type="image/png"
          :data="svgWarningYellow"
          width="48"
          height="48"
          @click="expanded = !expanded"
        >
          Seu navegador não suporta SVG.
        </object>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Popup",
  data() {
    return {
      expanded: true,
      svgWarningYellow: require("../../assets/warning_yellow.png"),
    };
  },
};
</script>

<style scoped>
.popup_icon {
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 6px;
  z-index: 4;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup_icon:hover,
.popup:hover {
  cursor: pointer;
}

.popup {
  position: fixed;
  bottom: 60px;
  right: 0px;
  max-width: 550px;
  height: 251px;
  border-radius: 8px;
  z-index: 4;
}

.header {
  text-align: center;
}

.title {
  font-weight: bold;
  margin-top: 10px;
  color: var(--text-default);
}
.content {
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  color: var(--custom-access-bg);
}

/* Adicione estilos adicionais conforme necessário */
</style>
