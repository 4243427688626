
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingBoxes",

  data() {
    return {
      imgCliente: require("../../assets/logo_cliente.png"),
    };
  },
});
