import axios, { AxiosInstance } from "axios";
import store from "./store";

//PORTA API
const porta_backend = "7087";

const base_url = window.location.origin;

let baseURL = "";

if (window.location.port.length === 4) {
  const arr = window.location.origin.split(":");
  baseURL = arr[0] + "s:" + arr[1];
}

baseURL =
  process.env.NODE_ENV === "production"
    ? base_url + "/api"
    : baseURL + ":" + porta_backend + "/api";

const instance: AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 600000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    const erro = error.response.status || "";

    if (401 === erro) {
      unauthorized();
    }

    return Promise.reject(error);
  },
);

function unauthorized() {
  const path = window.location.pathname;

  if (path !== "/login") {
    store.commit("defineSessaoExpirada", true);
  }
}

function setJwtToken() {
  const jwtToken = getJwtCookie();
  if (jwtToken) {
    instance.defaults.headers.Authorization = `Bearer ${jwtToken}`;
  }
}

function getJwtCookie() {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("session="))
    ?.split("=")[1];
  return cookieValue ? decodeURIComponent(cookieValue) : null;
}

setJwtToken();

export default instance;
